// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uJGHj_vRKSKB39WrSivw{padding:20px}.BciKQ3okJUHSzPeIdLwb{max-width:var(--default-layout-width);margin:0 auto}.mV6_qmFZKIwUsreNyWOp{display:flex;justify-content:center;align-items:center;margin-top:20px;margin-bottom:20px}.KlCG1J6ui3rF4v1UbuCn{width:100%;background:#fff;border-radius:8px;box-shadow:0 2px 5px rgba(0,0,0,.1);text-align:center;padding:10px;transition:transform .3s ease-in-out}.KlCG1J6ui3rF4v1UbuCn:hover{transform:translateY(-5px)}.zEx9zUBwLCLj00JyKg0w{max-width:100%;height:100px;object-fit:contain}.SHMYZrb6MZ8098tOWoz2 .W4Fob9QDpqJq2zdF6xhL{font-size:16px;color:#333;margin:0}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Partners/Partners.module.scss"],"names":[],"mappings":"AAGA,sBACI,YAAA,CAGJ,sBACI,qCAAA,CACA,aAAA,CAGJ,sBACI,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA,CAGJ,sBACI,UAAA,CACA,eAAA,CACA,iBAAA,CACA,mCAAA,CACA,iBAAA,CACA,YAAA,CACA,oCAAA,CAEA,4BACI,0BAAA,CAIR,sBACI,cAAA,CACA,YAAA,CACA,kBAAA,CAIA,4CACI,cAAA,CACA,UAAA,CACA,QAAA","sourcesContent":["@import '../../../styles/mixins';\r\n@import '../../../styles/variables';\r\n\r\n.wrapper {\r\n    padding: 20px;\r\n}\r\n\r\n.inner {\r\n    max-width: var(--default-layout-width);\r\n    margin: 0 auto;\r\n}\r\n\r\n.slide {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top: 20px;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.partner-card {\r\n    width: 100%;\r\n    background: #fff;\r\n    border-radius: 8px;\r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\r\n    text-align: center;\r\n    padding: 10px;\r\n    transition: transform 0.3s ease-in-out;\r\n\r\n    &:hover {\r\n        transform: translateY(-5px);\r\n    }\r\n}\r\n\r\n.partner-image {\r\n    max-width: 100%;\r\n    height: 100px;\r\n    object-fit: contain;\r\n}\r\n\r\n.partner-info {\r\n    .partner-position {\r\n        font-size: 16px;\r\n        color: #333;\r\n        margin: 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `uJGHj_vRKSKB39WrSivw`,
	"inner": `BciKQ3okJUHSzPeIdLwb`,
	"slide": `mV6_qmFZKIwUsreNyWOp`,
	"partner-card": `KlCG1J6ui3rF4v1UbuCn`,
	"partner-image": `zEx9zUBwLCLj00JyKg0w`,
	"partner-info": `SHMYZrb6MZ8098tOWoz2`,
	"partner-position": `W4Fob9QDpqJq2zdF6xhL`
};
export default ___CSS_LOADER_EXPORT___;
