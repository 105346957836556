// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aeRXHaAMP_OfTzyerBFM{display:flex;justify-content:center;margin-top:60px;padding:0 20px}.E7_YJaHp61yRWPovylUw{display:flex;gap:22px;width:var(--default-layout-width)}@media(max-width: 1024px){.E7_YJaHp61yRWPovylUw{width:100%;flex-direction:column}}`, "",{"version":3,"sources":["webpack://./src/pages/Home/NewsLibrary/NewsLibrary.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAGA,sBACI,YAAA,CACA,sBAAA,CACA,eAAA,CACA,cAAA,CAGJ,sBACI,YAAA,CACA,QAAA,CACA,iCAAA,CCCI,0BDJR,sBAMQ,UAAA,CACA,qBAAA,CAAA","sourcesContent":["@import '../../../styles/mixins';\r\n@import '../../../styles/variables';\r\n\r\n.wrapper {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 60px;\r\n    padding: 0 20px;\r\n}\r\n\r\n.inner {\r\n    display: flex;\r\n    gap: 22px;\r\n    width: var(--default-layout-width);\r\n\r\n    @include respond-to(lg) {\r\n        width: 100%;\r\n        flex-direction: column;\r\n    }\r\n}\r\n","@mixin respond-to($breakpoint) {\r\n    @if $breakpoint == xs {\r\n        @media (max-width: $breakpoint-xs) {\r\n            @content;\r\n        }\r\n    } @else if $breakpoint == sm {\r\n        @media (max-width: $breakpoint-sm) {\r\n            @content;\r\n        }\r\n    } @else if $breakpoint == md {\r\n        @media (max-width: $breakpoint-md) {\r\n            @content;\r\n        }\r\n    } @else if $breakpoint == lg {\r\n        @media (max-width: $breakpoint-lg) {\r\n            @content;\r\n        }\r\n    } @else if $breakpoint == xl {\r\n        @media (max-width: $breakpoint-xl) {\r\n            @content;\r\n        }\r\n    } @else if $breakpoint == 2xl {\r\n        @media (max-width: $breakpoint-2xl) {\r\n            @content;\r\n        }\r\n    } @else if $breakpoint == 3xl {\r\n        @media (max-width: $breakpoint-3xl) {\r\n            @content;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `aeRXHaAMP_OfTzyerBFM`,
	"inner": `E7_YJaHp61yRWPovylUw`
};
export default ___CSS_LOADER_EXPORT___;
