// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mSKcLY42s2TtOfgtiVgp{display:flex}.hsF1cN6EgWPZkKYwnZAD{width:100%;max-width:100%;display:flex;flex-direction:column}.iaHWKhJ6UdHjpxNg7XiX{flex:1}`, "",{"version":3,"sources":["webpack://./src/layouts/AdminLayout/AdminLayout.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAGJ,sBACI,UAAA,CACA,cAAA,CACA,YAAA,CACA,qBAAA,CAGJ,sBACI,MAAA","sourcesContent":[".wrapper {\r\n    display: flex;\r\n}\r\n\r\n.container {\r\n    width: 100%;\r\n    max-width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.content {\r\n    flex: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `mSKcLY42s2TtOfgtiVgp`,
	"container": `hsF1cN6EgWPZkKYwnZAD`,
	"content": `iaHWKhJ6UdHjpxNg7XiX`
};
export default ___CSS_LOADER_EXPORT___;
